<template>
    <b-row>
        <b-col cols="12">
            <b-card-actions :no-actions="true" title="List Stok Barang">
                <div v-if="isHeadwh || isSPV" class="d-flex align-items-center justify-content-between">
                    <section>
                        <h5>Lokasi Gudang: {{ gudangName }}</h5>
                    </section>
                    <section>
                        <b-button variant="outline-primary" @click.prevent="exportStock">
                            Export
                        </b-button>
                    </section>
                </div>
              <!--  -->
              <header>
              <b-row>
                    <!-- Page Limit -->
                    <b-col md="2" sm="4" class="my-1">
                        <b-form-group 
                        :label="$t('Per page')"
                        label-for="perPageSelect"
                        class="mb-0">
                        <b-form-select
                            id="perPageSelect"
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                        />
                        </b-form-group>
                    </b-col>
                    <!-- / -->
                    <!-- Sort By -->
                    <b-col md="4" sm="8" class="my-1">
                        <b-form-group
                        :label="$t('Sort')"
                        label-for="sortBySelect"
                        class="mb-0"
                        >
                        <b-input-group size="sm">
                            <b-form-select
                            id="sortBySelect"
                            v-model="sortBy"
                            :options="sortOptions"
                            class="w-75"
                            >
                            <template v-slot:first>
                                <option value="">-- none --</option>
                            </template>
                            </b-form-select>
                            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                            <option :value="false">Asc</option>
                            <option :value="true">Desc</option>
                            </b-form-select>
                        </b-input-group>
                        </b-form-group>
                    </b-col>
                    <!-- / -->
                    <!-- Filter Search -->
                    <b-col md="6" class="my-1">
                        <b-form-group
                        :label="$t('Filter')"
                        label-for="filterInput"
                        class="mb-0"
                        >
                        <b-input-group size="sm">
                            <b-form-input
                            id="filterInput"
                            v-model="filter"
                            type="search"
                            
                            />
                            <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        </b-form-group>
                    </b-col>
                    <!-- / -->
                </b-row>
            </header>
            <!-- /form filter -->
            <b-table
            striped
            small
            hover
            responsive
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            >
                <!-- Check box for show detail -->
            <template #thead-top="data">
              <b-tr>
                <b-th colspan="6" style="background-color: transparent"></b-th>
                <!-- <b-th variant="secondary">Type 1</b-th> -->
                <b-th colspan="4" style="" class="text-center"
                  >Lokasi Penyimpanan</b-th
                >
                <!-- <b-th ></b-th> -->
              </b-tr>
            </template>
                <template #cell(show_detail)="row">
                    <b-form-checkbox
                    v-if="row.item.stocks && row.item.stocks.length > 0"
                    v-model="row.detailsShowing"
                    plain
                    class="vs-checkbox-con"
                    @change="row.toggleDetails"
                    >
                    <span class="vs-checkbox">
                        <span class="vs-checkbox--check">
                        <i class="vs-icon feather icon-check" />
                        </span>
                    </span>
                    <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
                    </b-form-checkbox>
                    <span v-else class="text-danger">
                        <feather-icon icon="SlashIcon" />
                    </span>
                </template>
            <template #cell(blok)="{ item }">
              {{ item.stocks.length > 0 ? item.stocks[0].blok ? item.stocks[0].blok.blok : "-" : "-" }}
            </template>
            <template #cell(palet)="{ item }">
              {{ item.stocks.length > 0 ? item.stocks[0].palet ? item.stocks[0].palet.palet : "-" : "-" }}
            </template>
            <template #cell(rak)="{ item }">
              {{ item.stocks.length > 0 ? item.stocks[0].rak ? item.stocks[0].rak.rak : "-" : "-" }}
            </template>
            <template #cell(laci)="{ item }">
              {{ item.stocks.length > 0 ? item.stocks[0].laci ? item.stocks[0].laci.laci : "-" : "-" }}
            </template>
                <!-- / -->
                <!-- Detail -->
                <template #row-details="{item, toggleDetails}">
                <b-card>
                    <b-row class="mb-2">
                        <b-col cols="12">
                            <table>
                                <tr>
                                    <th>No</th>
                                    <th>Gudang</th>
                                    <th>Blok</th>
                                    <th>Pallet</th>
                                    <th>Rak</th>
                                    <th>Laci</th>
                                    <th>Stok</th>
                                </tr>
                                <tr :key="i" v-for="(stock, i) in item.stocks">
                                    <td>{{(i+1)}}</td>
                                    <td>
                                        {{ stock.gudang ? stock.gudang.nama_gudang : '-' }}
                                    </td>
                                    <td>{{ stock.blok ? `${stock.blok.blok}` : '-' }}</td>
                                    <td>{{ stock.palet ? `${stock.palet.palet}` : '-' }}</td>
                                    <td>{{ stock.rak ? `${stock.rak.rak}` : '-' }}</td>
                                    <td>{{ stock.laci ? `${stock.laci.laci}` : '-' }}</td>
                                    <td>{{ formatRupiah(stock.stok) }}</td>
                                </tr>
                            </table>
                        </b-col>
                    </b-row>

                    <b-button
                    size="sm"
                    variant="outline-secondary"
                    @click="toggleDetails"
                    >
                    Hide
                    </b-button>
                </b-card>
                </template>
                <!-- / -->
                <template #cell(no)="{index}">
                    {{ (index + 1) }}
                </template>
                <template #cell(varian)="{item}">
                    {{ item ? item.varian : '-' }}
                </template>
                <template #cell(kategori)="{item}">
                    {{ item.kategori ? item.kategori.kategori : '-' }}
                </template>
                <template #cell(stocks)="{item}">
                    {{ item.stocks.length > 0 ? formatRupiah(getTotal(item.stocks)) : 'stok kosong' }}
                </template>
                <template #cell(satuan)="{item}">
                    {{ item.satuan ? item.satuan.satuan : '-' }}
                </template>
                <template #cell(action)="{ item }">
                    <section class="d-flex justify-content-center" v-if="allowUpdate()">
                        <span class="text-danger" v-if="item.stocks.length < 1">
                            <feather-icon icon="SlashIcon"></feather-icon>
                        </span>
                        <b-button
                        v-else
                        :id="`edit-btn-${item.id}`"
                        size="sm"
                        @click.prevent="viewDetail(item)"
                        class="mr-1"
                        variant="outline-info"
                        >
                            <feather-icon icon="EditIcon"/>
                        </b-button>
                        <b-tooltip :target="`edit-btn-${item.id}`">Edit</b-tooltip>
                    </section>
                </template>
            </b-table>
            <b-row>
                <b-col cols="12">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="center"
                        size="sm"
                        class="my-0"
                    />
                </b-col>
            </b-row>
              <!-- / -->
            </b-card-actions>
        </b-col>
    </b-row>
</template>
<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
BRow, BCol, BInputGroup, BInputGroupAppend, BAvatar, BBadge, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,BButton, BCardText, BTable, BFormCheckbox, BTooltip, BPagination
} from 'bootstrap-vue'
import service from '@/services'

export default {
    data: () => ({
        gudang_id: null,
        barangs: [],
        items: [],
        pageOptions: [10, 50, 100],
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        perPage: 10,
        totalRows: 1,
        currentPage: 1,
        fields: [
            // {key: 'show_detail', label: '#'},
            {key: 'no', label: 'No'},
            {key: 'nama', label: 'Nama Barang', sortable: true},
            {key: 'varian', label: 'Varian', sortable: true},
            {key: 'kategori', label: 'Kategori', sortable: true},
            {key: 'stocks', label: 'Jumlah Stok', sortable: true},
            {key: 'satuan', label: 'Satuan'},
      { key: "blok", label: "Blok", sortable: true },
      { key: "palet", label: "Palet", sortable: true },
      { key: "rak", label: "Rak", sortable: true },
      { key: "laci", label: "Laci", sortable: true },
            {key: 'action', label: '#'}
        ]
    }),
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
            .filter((f) => f.sortable)
            .map((f) => ({ text: f.label, value: f.key }));
        }
    },
    components: {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
        BInputGroupAppend,
        BFormCheckbox,
        BTable,
        BPagination,
        BTooltip,
        BFormCheckbox,
        BInputGroup,
        BButton,
        BRow,
        BCol,
      BCardActions,
      BAvatar,
      BBadge,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BDropdown,
      BDropdownItem,
    },
    
  watch: {
    perPage(value) {
      if (value) {
        this.getBarang();
      }
    },
    filter(keyword) {
      if (keyword.length > 2 || keyword.length == 0) {
        this.getBarang();
      }
    },
    currentPage(val) {
      if (val) {
        this.getBarang();
        // this.items
      }
    },
  },
    methods: {
        async exportStock() {
            try {
                const params = {}
                if(this.myGudang) params.gudang_id = this.myGudang.id
                const config = {
                    url: `/export-stok-gudang`,
                    method: 'get',
                    params,
                    headers: {
                        Authorization: `${localStorage.tokenType} ${localStorage.token}`
                    }
                }

                const response = await service.sendRequest(config)
                await window.open(response.data)
                setTimeout(async () => {
                    // delete selected file
                    const arrFile = response.data.split('/')
                    const filename = arrFile[ arrFile.length - 1 ]

                    await this.clearExportedFile(filename)
                }, 1000)
            }
            catch(e) {
                this.displayError(e)
                return false
            }
        },
        async clearExportedFile(filename) {
            try {
                const config = {
                    url: `/clear-export`,
                    method: 'get',
                    params: {
                        filename
                    },
                    headers: {
                        Authorization: `${localStorage.tokenType} ${localStorage.token}`
                    }
                }
                const response = await service.sendRequest(config)

                return Promise.resolve(response.data)
            } catch (e) {
                if(e.response && e.response.data) {
                    const blob = e.response.data
                    const text = await blob.text()
                    const errorJson = JSON.parse(text)

                    return Promise.reject(errorJson)
                }
                return Promise.reject(e)        
            }
        },
        getTotal(stocks) {
            return stocks.reduce((total, item) => item.stok + total, 0)
        },
        getBarang() {
            const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
        filter_by: "nama_lengkap",
      };
      this.$store
        .dispatch("barang/getData", payload)
            .then(barangs => {
                this.barangs = JSON.parse(JSON.stringify(this.$store.state.barang.datas))
                // get barangsId
                const barangsId = barangs.map(barang => barang.id)
                
                // get stock
                this.getStockBarang(barangsId)
                this.totalRows = this.$store.state.barang.totals;
            })
            .catch(e => {
                this.displayError(e)
                return false
            })
        },
        viewDetail(item) {
            this.$store.commit('penyimpanan/SET_DETAIL_DATA', item)
            setTimeout(() => this.$router.push({name: 'route-stock-in-edit', params: {id: item.id}}), 500)
        },
        getStockBarang(barangsId) {
            let params = {
                barang_id: barangsId.join(','),
            }
            if(this.gudang_id) {
                params.gudang_id = this.gudang_id
            }

            this.$store.dispatch('penyimpanan/getData', params)
            .then(stocks => {
                this.barangs.map(barang => {
                    barang.stocks = []
                    const findStocks = stocks.filter(stock => stock.barang && stock.barang.id == barang.id)

                    if(findStocks && findStocks.length > 0) {
                        findStocks.forEach(stock => {
                            barang.stocks.push({
                                id: stock.id,
                                stok: stock.stok,
                                gudang: stock.gudang,
                                blok: stock.blok,
                                palet: stock.palet,
                                rak: stock.rak,
                                laci: stock.laci,
                                stok: stock.stok
                            })
                        })
                    }
                })

                this.items = this.barangs
            })
            .catch(e => {
                this.displayError(e)
                return false
            })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        }
    },
    created() {
        this.getBarang()
    }
}
</script>