var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card-actions', {
    attrs: {
      "no-actions": true,
      "title": "List Stok Barang"
    }
  }, [_vm.isHeadwh || _vm.isSPV ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('section', [_c('h5', [_vm._v("Lokasi Gudang: " + _vm._s(_vm.gudangName))])]), _c('section', [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.exportStock($event);
      }
    }
  }, [_vm._v(" Export ")])], 1)]) : _vm._e(), _c('header', [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Per page'),
      "label-for": "perPageSelect"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Sort'),
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function ($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function ($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "thead-top",
      fn: function (data) {
        return [_c('b-tr', [_c('b-th', {
          staticStyle: {
            "background-color": "transparent"
          },
          attrs: {
            "colspan": "6"
          }
        }), _c('b-th', {
          staticClass: "text-center",
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v("Lokasi Penyimpanan")])], 1)];
      }
    }, {
      key: "cell(show_detail)",
      fn: function (row) {
        return [row.item.stocks && row.item.stocks.length > 0 ? _c('b-form-checkbox', {
          staticClass: "vs-checkbox-con",
          attrs: {
            "plain": ""
          },
          on: {
            "change": row.toggleDetails
          },
          model: {
            value: row.detailsShowing,
            callback: function ($$v) {
              _vm.$set(row, "detailsShowing", $$v);
            },
            expression: "row.detailsShowing"
          }
        }, [_c('span', {
          staticClass: "vs-checkbox"
        }, [_c('span', {
          staticClass: "vs-checkbox--check"
        }, [_c('i', {
          staticClass: "vs-icon feather icon-check"
        })])]), _c('span', {
          staticClass: "vs-label"
        }, [_vm._v(_vm._s(row.detailsShowing ? 'Hide' : 'Show'))])]) : _c('span', {
          staticClass: "text-danger"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "SlashIcon"
          }
        })], 1)];
      }
    }, {
      key: "cell(blok)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.stocks.length > 0 ? item.stocks[0].blok ? item.stocks[0].blok.blok : "-" : "-") + " ")];
      }
    }, {
      key: "cell(palet)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.stocks.length > 0 ? item.stocks[0].palet ? item.stocks[0].palet.palet : "-" : "-") + " ")];
      }
    }, {
      key: "cell(rak)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.stocks.length > 0 ? item.stocks[0].rak ? item.stocks[0].rak.rak : "-" : "-") + " ")];
      }
    }, {
      key: "cell(laci)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.stocks.length > 0 ? item.stocks[0].laci ? item.stocks[0].laci.laci : "-" : "-") + " ")];
      }
    }, {
      key: "row-details",
      fn: function (_ref5) {
        var item = _ref5.item,
          toggleDetails = _ref5.toggleDetails;
        return [_c('b-card', [_c('b-row', {
          staticClass: "mb-2"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('table', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Gudang")]), _c('th', [_vm._v("Blok")]), _c('th', [_vm._v("Pallet")]), _c('th', [_vm._v("Rak")]), _c('th', [_vm._v("Laci")]), _c('th', [_vm._v("Stok")])]), _vm._l(item.stocks, function (stock, i) {
          return _c('tr', {
            key: i
          }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(" " + _vm._s(stock.gudang ? stock.gudang.nama_gudang : '-') + " ")]), _c('td', [_vm._v(_vm._s(stock.blok ? ("" + (stock.blok.blok)) : '-'))]), _c('td', [_vm._v(_vm._s(stock.palet ? ("" + (stock.palet.palet)) : '-'))]), _c('td', [_vm._v(_vm._s(stock.rak ? ("" + (stock.rak.rak)) : '-'))]), _c('td', [_vm._v(_vm._s(stock.laci ? ("" + (stock.laci.laci)) : '-'))]), _c('td', [_vm._v(_vm._s(_vm.formatRupiah(stock.stok)))])]);
        })], 2)])], 1), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-secondary"
          },
          on: {
            "click": toggleDetails
          }
        }, [_vm._v(" Hide ")])], 1)];
      }
    }, {
      key: "cell(no)",
      fn: function (_ref6) {
        var index = _ref6.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(varian)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item ? item.varian : '-') + " ")];
      }
    }, {
      key: "cell(kategori)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.kategori ? item.kategori.kategori : '-') + " ")];
      }
    }, {
      key: "cell(stocks)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.stocks.length > 0 ? _vm.formatRupiah(_vm.getTotal(item.stocks)) : 'stok kosong') + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.satuan ? item.satuan.satuan : '-') + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_vm.allowUpdate() ? _c('section', {
          staticClass: "d-flex justify-content-center"
        }, [item.stocks.length < 1 ? _c('span', {
          staticClass: "text-danger"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "SlashIcon"
          }
        })], 1) : _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "id": ("edit-btn-" + (item.id)),
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.viewDetail(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1), _c('b-tooltip', {
          attrs: {
            "target": ("edit-btn-" + (item.id))
          }
        }, [_vm._v("Edit")])], 1) : _vm._e()];
      }
    }])
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }